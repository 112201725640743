<template>
  <div style="margin: 10px">
    <fp-input
      style="margin: 0px 5px; max-width: 200px"
      :label="$t('base.basic.filter')"
      @change="filterList"
      v-model="filter"
    />
    <fp-table-list
      :hasPagination="true"
      :items="filteredQuestionnaireList"
      :fields="formListFields"
      sortBy="CreatedAt"
    >
      <template #Patient="row">
        <div v-if="row.item.Patient">
          <div>{{ row.item.Patient.Name }}</div>
          <div>{{ row.item.Patient.Ssn }}</div>
          <div>
            {{
              $convertDateToString(
                row.item.Patient.PatientDateOfBirth,
                "YYYY-MM-DD"
              )
            }}
          </div>
        </div>
      </template>
      <template #Title="row">
        {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
      </template>
      <template #Description="row">
        {{ getTextByLanguage(row.item.Description, row.item.Languages) }}
      </template>
      <template #ProgressPercentage="row">
        <b-progress :value="row.item.FillingRate" :max="100"></b-progress>
        <div style="text-align: center">
          {{ row.item.FillingRate ? row.item.FillingRate + "%" : "0%" }}
        </div>
      </template>
      <template #CreatedAt="row">
        <div style="white-space: nowrap">
          {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
        </div>
      </template>
      <template #FilledDate="row">
        <div style="white-space: nowrap">
          {{ $convertDateToString(row.item.FilledDate, "YYYY-MM-DD HH:mm") }}
        </div>
      </template>
      <template #Status="row">
        {{ $t(formStatuses[row.item.Status].Text) }}
      </template>
      <template #OpenQuestionnaire="row">
        <router-link
          :to="{
            path: 'Form',
            query: { Id: row.item.FormInstanceId },
          }"
          ><b-button>
            {{
              [
                formStatuses.Filled.Value,
                formStatuses.Evaluated.Value,
                formStatuses.UnderEvaluation.Value,
              ].includes(row.item.Status)
                ? $t("base.basic.view")
                : $t("base.basic.filling")
            }}
          </b-button></router-link
        >
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import { FormLogic } from "../../../Logic/Backend/form";
import { ProjectLogic } from "../../../Logic/Backend/project.js";

export default {
  name:'FormFillableList',
  data() {
    return {
      formStatuses: this.$enums.QuestionnaireStatus,
      formList: [],
      formListFields: [
        {
          key: "Patient",
          label: this.$t(
            "healthProfessional.questionnaires.fillableFormTab.patient"
          ),
        },
        {
          key: "Title",
          label: this.$t(
            "healthProfessional.questionnaires.fillableFormTab.title"
          ),
        },
        {
          key: "Description",
          label: this.$t(
            "healthProfessional.questionnaires.fillableFormTab.description"
          ),
        },
        {
          key: "ProgressPercentage",
          label: this.$t(
            "healthProfessional.questionnaires.fillableFormTab.percentage"
          ),
        },
        {
          key: "CreatedAt",
          label: this.$t(
            "healthProfessional.questionnaires.fillableFormTab.startDateTime"
          ),
          sortable: true,
        },
        {
          key: "FilledDate",
          label: this.$t(
            "healthProfessional.questionnaires.fillableFormTab.stopDateTime"
          ),
          sortable: true,
        },
        {
          key: "Status",
          label: this.$t(
            "healthProfessional.questionnaires.fillableFormTab.status"
          ),
        },
        { key: "OpenQuestionnaire", label: "" },
      ],
      filteredQuestionnaireList: null,
      filter: null,
      patientList: null,
    };
  },
  props: {
    projectItems: Array,
  },
  watch: {
    async projectItems() {
      await this.getList();
    },
  },
  methods: {
    getTextByLanguage(textObject, languages) {
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    filterList() {
      this.filteredQuestionnaireList = this.formList;
      if (this.filter) {
        this.filteredQuestionnaireList = this.$filterList(
          this.filter,
          this.formList,
          [
            "Title",
            "Description",
            "Patient.Name",
            "Patient.Ssn",
            "Patient.PatientDateOfBirth",
          ],
          this.$i18n.locale
        );
      }
    },
    //TODO: beteg adatok behúzá (WaitForEvaluate szerint)
    //kérdőívek lista kérés
    async getList() {
      await this.getPatientList();
      //kérés indítás
      const formListResponse = await FormLogic.getFormInstanceHeader({
        FormCategory: "Form",
        AssigneeId: this.$loggedUser.UserId,
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      //sikeres kérés?
      if (!formListResponse.Code) {
        formListResponse.forEach((form) => {
          const patient = this.patientList.find(
            (p) => p.UserId == form.SubjectId
          );
          if (patient) {
            form.Patient = {
              Name: patient.Name,
              Ssn: patient.Ssn,
              PatientDateOfBirth: patient.PatientDateOfBirth,
            };
          }
        });
        //kérdőívek lista frissítés
        this.formList = this.filteredQuestionnaireList = formListResponse.filter(
          (form) => form.Patient
        );
      } else {
        this.$bvToast.toast(formListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t(
              "healthProfessional.questionnaires.fillableFormTab.errorName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //beteg lista kérés
    async getPatientList() {
      //kérés indítás
      const patientListResult = await ProjectLogic.getProjectSubordinates(
        this.$loggedUser.SelectedProject.ProjectId
      );
      //sikeres kérés?
      if (!patientListResult.Code) {
        //beteg lista frissítés
        this.patientList = patientListResult;
      } else {
        this.$bvToast.toast(patientListResult.Message, {
          title: this.$t("requestResponse.project.errorGetProjectSubordinates"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //kérdőívek listájának betöltése
    await this.getList();
  },
};
</script>
<style>
.questionnaire {
  padding: 50px 0px 100px 0px;
}
</style>
